export const addScriptTagToHead = (id: string, content: string) => {
  if (scriptTagExistsById(id)) {
    return;
  }

  const script = document.createElement('script');
  script.id = id;
  script.innerHTML = content;
  document.head.appendChild(script);
};

export const scriptTagExistsById = (id: string) => {
  return document.getElementById(id) !== null;
};
