import { fork, takeEvery } from 'saga-ts';
import { firebase } from '../utils/globals';
import { navigateToLogin } from './helpers/navigation';
import { setBoolean } from '../utils/storage';
// import * as a from '../utils/analytics';

type ActionLogout = {
  type: 'LOGOUT';
};

export function* logout(_action: ActionLogout) {
  try {
    yield firebase.signOut();
    localStorage.clear();
    sessionStorage.clear();
    setBoolean('jotFormShown', true);
    setBoolean('USER_LOGGED_IN_ONCE', true);
    yield* fork(navigateToLogin);
  } catch (err: any) {}

  // a.logoutSuccess();

  document.location.href = '/login';
}

export function* root() {
  yield takeEvery('LOGOUT', logout);
}
