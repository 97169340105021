import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  // Chat
  {
    id: 'chat',
    title: 'Chat',
    path: `${AppRoutes.PLAY_HOME}/chat/single-chat`,
    groupId: 'playgrounds',
    iconName: 'MessageCircle',
    order: 0,
  },
  // Conversational RAG
  {
    id: 'conversational-rag',
    title: 'Conversational RAG',
    path: `/v2/conversational-rag`,
    groupId: 'playgrounds',
    iconName: 'ConversationalRag',
    order: 1,
    featureFlag: 'rag-chat-page-enabled',
  },
  // Complete
  {
    id: 'complete',
    title: 'Complete',
    path: `${AppRoutes.PLAY_HOME}/complete/single-input-1-output`,
    groupId: 'playgrounds',
    iconName: 'Playground',
    order: 2,
  },
  // Contextual Answers
  {
    id: 'answer',
    title: 'Contextual Answers',
    path: `${AppRoutes.PLAY_HOME}/task-specific-models/contextual-answers`,
    groupId: 'playgrounds',
    iconName: 'QuestionMarkIcon',
    order: 3,
  },

  // Summarize
  {
    id: 'summarize',
    title: 'Summarize',
    groupId: 'playgrounds',
    path: `${AppRoutes.PLAY_HOME}/task-specific-models/summarize`,
    //parentId: 'summarization',
    iconName: 'SummarizationApi',
    order: 4,
  },

  // Text editing Group
  {
    id: 'writing',
    title: 'Text editing',
    groupId: 'playgrounds',
    iconName: 'RewriteApi',
    isParent: true,
    order: 5,
  },
  // Paraphrase
  {
    id: 'paraphrase',
    title: 'Paraphrase',
    groupId: 'playgrounds',
    path: `${AppRoutes.PLAY_HOME}/task-specific-models/paraphrase`,
    parentId: 'writing',
    order: 0,
  },
  // Text Improvements
  {
    id: 'textImprovements',
    title: 'Text Improvements',
    groupId: 'playgrounds',
    path: `${AppRoutes.PLAY_HOME}/task-specific-models/text-improvements`,
    parentId: 'writing',
    order: 1,
  },
  // Grammatical Error Corrections
  {
    id: 'gec',
    title: 'Grammatical Error Correction',
    groupId: 'playgrounds',
    path: `${AppRoutes.PLAY_HOME}/task-specific-models/grammatical-error-correction`,
    parentId: 'writing',
    order: 2,
  },
];

export const sideBarGroups: IMenuGroup[] = [{ id: 'playgrounds', order: 1 }];
