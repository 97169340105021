import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { WebRoutes } from './Web.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'datasets',
    title: 'Datasets',
    path: WebRoutes.datasets,
    groupId: 'assetsAndTools',
    iconName: 'menu.DataBase',
    hoverIconName: 'ChevronRight',
    order: 1,
  },
  {
    id: 'models',
    title: 'Custom models',
    path: WebRoutes.customModels,
    groupId: 'assetsAndTools',
    iconName: 'menu.Train',
    order: 3,
  },
  {
    id: 'fineTune',
    title: 'Fine-tuning sets',
    path: WebRoutes.fineTuningSets,
    groupId: 'assets',
    iconName: 'menu.DataBase',
    order: 1,
    isChild: true,
  },
  {
    id: 'presets',
    title: 'Saved presets',
    path: WebRoutes.presets,
    groupId: 'assets',
    iconName: 'menu.Template',
    order: 3,
    isChild: true,
  },
  /////////////////////////
  // Account + sub menu
  {
    id: 'account',
    title: 'Settings',
    path: WebRoutes.accountDetails,
    iconName: 'menu.Settings',
    groupId: 'account',
    isHidden: true,
  },
  {
    id: 'accountDetails',
    title: 'Account details',
    path: WebRoutes.accountDetails,
    iconName: 'menu.Settings',
    groupId: 'account',
    isChild: true,
  },
  {
    id: 'accountKeys',
    title: 'API key',
    path: WebRoutes.apiKey,
    iconName: 'menu.Code',
    groupId: 'account',
    isChild: true,
  },
  {
    id: 'organization',
    title: 'Organization',
    path: WebRoutes.organization,
    iconName: 'menu.Users',
    groupId: 'account',
    isChild: true,
  },
  {
    id: 'billing',
    title: 'Billing & Plans',
    path: WebRoutes.billingPlans,
    iconName: 'menu.Billing',
    groupId: 'account',
    isChild: true,
  },
  {
    id: 'modelUsage',
    title: 'Model usage',
    path: WebRoutes.modelUsage,
    iconName: 'menu.GoogleAnalytics',
    groupId: 'account',
    isChild: true,
  },
  ////////////////////////////////////
  {
    id: 'tools',
    title: 'Tools',
    path: WebRoutes.tools,
    groupId: 'assetsAndTools',
    iconName: 'Tool',
    hoverIconName: 'ChevronRight',
    order: 2,
  },
  // More + sub menu
  {
    id: 'more',
    title: 'More',
    groupId: 'top',
    iconName: 'Box',
    isParent: true,
    order: 6,
    description: 'Available via API',
  },
  {
    id: 'semanticSearch',
    title: 'Semantic Search',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/semantic-search-api',
    parentId: 'more',
    order: 0,
  },
  {
    id: 'embeddings',
    title: 'Embeddings',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/embeddings-api',
    parentId: 'more',
    order: 1,
  },
  {
    id: 'textSegmentation',
    title: 'Text Segmentation',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/text-segmentation-api',
    parentId: 'more',
    order: 2,
  },
  ///////////////////////////
];

export const sideBarGroups: IMenuGroup[] = [
  {
    id: 'home',
    order: 0,
  },
  {
    id: 'assetsAndTools',
    title: 'Assets & Tools',
    order: 2,
  },
  { id: 'assets', title: 'DATASETS', order: 1 },
  { id: 'account', title: 'Account', order: 3 },
];

export const hideMenuPaths = [
  'sandbox',
  'widgets',
  'login',
  'sign-up',
  'public',
  'check-email',
  'check-email-done',
  'forgot-password',
  'forgot-password-check',
  'forgot-password-done',
  'sso-login',
  'lp',
];
