import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { DOCS_ROUTE, ROOT_ROUTE } from '../constants/constants';
import { WebRoutes } from '../bootstrap/Web.routes';

export const topBarItems: IMenuItem[] = [
  {
    id: 'docs',
    title: 'Documentation',
    href: DOCS_ROUTE,
    groupId: 'top',
    order: 0,
  },
  {
    id: 'myAccount',
    title: 'Settings',
    path: WebRoutes.accountDetails,
    groupId: 'menu',
    iconName: 'menu.Settings',
  },
  {
    id: 'apiKey',
    title: 'API key',
    iconName: 'menu.Code',
    path: WebRoutes.apiKey,
    hasDivider: true,
    groupId: 'menu',
  },
  {
    id: 'privacyPolicy',
    title: 'Privacy policy',
    path: WebRoutes.privatePolicy,
    groupId: 'menu',
  },
  {
    id: 'termsOfUser',
    title: 'Terms of use',
    path: WebRoutes.termsOfUse,
    groupId: 'menu',
  },
  {
    id: 'logout',
    title: 'Logout',
    actionType: 'LOGOUT_ASK',
    groupId: 'menu',
  },
];

export const topBarGroups: IMenuGroup[] = [
  {
    id: 'top',
  },
  {
    id: 'menu',
  },
];
