import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'RagEngine',
    title: 'RAG Engine',
    path: AppRoutes.RagEngine,
    groupId: 'assetsAndTools',
    iconName: 'Folder',
    order: 0,
    // isChild: true,
  },
];

export const sideBarGroups: IMenuGroup[] = [];
