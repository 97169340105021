import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  tokens,
} from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: ${tokens.primitives.coal[50]};
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  margin-top: 56px;
  z-index: 3;
  overflow-x: hidden;

  --time-open: 0.2s;
  --time-close: 0.1s;

  transition: all var(--time-open) linear;
  }

  h3 {
    max-height: 100px;
    max-width: 200px;
    white-space: nowrap;
  }

  .MuiTypography-root {
    width: 200px;
    font-weight: 500;
    white-space: nowrap;
  }

  &.inner {
    .main-group {
      left: -250px;
    }

    .child-group {
      left: 0;
    }
  }

  &.hidden {
    left: -250px;
  }

  &.slim {
    width: 70px;
    padding: 0;
    display: none;

    .group {
      padding: 3px 6px;
    }

    .MuiTypography-root {
      width: 0;
      overflow: hidden;
    }

    h3 {
      max-width: 0;
      max-height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      font-size: 6px;
      opacity: 0;
    }

    .MuiListItemIcon-root {
      svg {
        width: 26px;
        height: 26px;
        opacity: 0.7;
      }
    }

    &:hover {
      .MuiListItemIcon-root {
        svg {
          opacity: 1;
          transition: all var(1s) linear;
        }
      }
    }

    .MuiBadge-badge {
      display: none;
    }

    .MuiListItemButton-root {
      width: 60px;
    }
  }
`;

export const AllGroups = styled.div`
  max-width: 250px;
  min-height: calc(100vh - 60px);
  overflow-x: hidden;
  position: relative;
`;

export const MainGroups = styled.div`
  position: absolute;
  width: 250px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* transition: all 0.2s ease-in-out; */
`;

export const ChildGroups = styled(MainGroups)`
  left: 250px;
`;

export const Flex = styled.div`
  flex: 1;
`;

export const DropdownIcon = styled('div')`
  position: relative;
  left: 6px;
  font-size: 10px;
  color: ${tokens.primitives.coal[1000]};
  padding: 2px 4px;
  border-radius: 4px;
  bottom: -1px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const OnHoverIcon = styled('div')`
  position: relative;
  left: 6px;
  font-size: 10px;
  color: ${tokens.primitives.coal[1000]};
  padding: 2px 4px;
  border-radius: 4px;
  bottom: -1px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Item = styled(ListItemButton)`
  background-color: ${tokens.primitives.coal[50]};
  text-align: left;
  padding-left: 8px;
  border-radius: 8px;
  height: 36px;
  margin: 1px 0;

  ${OnHoverIcon} {
    display: none;
  }

  &:hover {
    ${OnHoverIcon} {
      display: block;
    }
  }

  &.parent {
    &.Mui-selected {
      background-color: ${tokens.primitives.coal[50]};
      color: ${tokens.primitives.coal[1000]};
    }
  }

  &.Mui-selected {
    background-color: ${tokens.primitives.coal[90]};
    ${DropdownIcon} {
      display: flex;
      align-items: center;
      transform: rotate(180deg);
      &:hover {
        color: ${tokens.primitives.coal[1000]};
      }
    }

    &:hover {
      background-color: ${tokens.primitives.coal[90]};
      &:hover {
        color: ${tokens.primitives.coal[1000]};
      }
    }
  }

  & + .dropdown {
    transition: max-height 0.2s ease-in-out, padding 0.3s ease-in-out, margin 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    & .external {
      color: ${tokens.disabled.text};
    }

    .dropdownItem {
      span {
        text-overflow: ellipsis;
        width: 140px;
        overflow: hidden;
      }
    }
  }

  &.Mui-selected + .dropdown {
    max-height: 200px; /* Adjust according to your content height */
    padding-bottom: 8px;
  }

  &:hover {
    background-color: ${tokens.primitives.coal[75]};
  }
`;

export const BackItem = styled(Item)`
  margin: 8px 12px 2px 12px;
  border: 1px solid;
  border-color: ${tokens.primitives.coal[200]};
  margin-bottom: 20px;

  svg {
    margin-right: 12px;
    stroke-width: 1.5px;
    color: ${tokens.primitives.coal[1000]};
    width: 27px;
    &:hover {
      color: ${tokens.primitives.coal[1000]};
    }
  }
`;

export const ItemIcon = styled(ListItemIcon)`
  min-width: 32px;

  svg {
    stroke-width: 1.5px;
    color: ${tokens.primitives.coal[1000]};
    width: 20px;
    height: 20px;
    &:hover {
      color: ${tokens.primitives.coal[1000]};
    }
  }
`;

export const ItemText = styled(ListItemText)`
  color: ${tokens.primitives.coal[1000]};
  font-weight: 500;
  &:hover {
    color: ${tokens.primitives.coal[1000]};
  }
`;

export const ItemBadge = styled('div')`
  position: relative;
  left: 6px;
  font-size: 10px;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  bottom: 1px;

  &.AI21 {
    background-color: #e91e63;
  }

  &.Beta {
    background-color: ${tokens.neutral.almostBlack};
  }
`;

export const Group = styled(List)`
  display: flex;
  flex-direction: column;
  padding: 0px 8px 12px 8px;

  &.children {
    margin-top: 0;
    padding: 0;

    & > .item {
      margin-left: 12px;
      margin-right: 12px;

      &:last-child {
        margin-bottom: 12px;
      }
      &:nth-child(2) {
        /* margin-top: 12px; */
      }
    }
  }

  &.bottom {
    position: absolute;
    bottom: 0;
    width: 216px;
  }
`;

export const GroupTitle = styled.h3`
  color: ${tokens.primitives.coal[500]};
  padding: 10px 0;
  margin: 0;
  padding-left: 20px;
  font-weight: 600;
  font-size: 10px;
`;

export const ChildGroupTitle = styled.h3`
  color: ${tokens.primitives.coal[500]};
  padding: 10px 0;
  margin: 0;
  padding-left: 32px;
  font-weight: 700;
  font-size: 10px;
`;

export const BackWrapper = styled.div``;

export const ExternalIcon = styled.div`
  position: relative;
  left: 6px;
  font-size: 10px;
  color: ${tokens.primitives.coal[1000]};
  padding: 2px 4px;
  border-radius: 4px;
  bottom: -1px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const Description = styled(Typography)`
  padding-left: 48px;
`;
