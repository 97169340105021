import { call, fork, takeEvery } from 'saga-ts';
import { getJson, invokeEvent, setBoolean } from 'shared-base';
import { authState } from '../../utils/globals';
import { AuthStage } from '../../types';
import { logger } from '../../utils/log';
import { clearJson } from '../../utils/storage';

export function* navigateToLogin(replace?: boolean) {
  yield* fork(navigate, '/login', replace);
}

export function* navigateToDefaultPath() {
  const { options } = authState;

  yield* fork(navigate, options.defaultRoute);
}

// step #8
export function* navigateToOriginalLocation() {
  const original = getJson('originalLocation');

  logger.RestoreRequestedRoute({ original });

  const isExternal = yield* call(checkExternal);

  if (isExternal) {
    return;
  }

  if (!original) {
    yield fork(navigateToDefaultPath);
    return;
  }

  clearJson('originalLocation');
  const { pathname, search, hash } = original;

  // internal page
  let to = pathname;

  if (search) {
    to += search;
  }

  if (hash) {
    to += hash;
  }

  yield* fork(navigate, to);
}

export function* checkExternal() {
  // external site (docs, etc.) => current search params are used
  const searchParams = new URLSearchParams(document.location.search);
  const src = searchParams.get('src');

  if (src) {
    const path = searchParams.get('redirect') ?? '';
    invokeEvent('saga/external', { src, path, ...authState.user });
    return true;
  }

  return false;
}

export function* navigateToJotform() {
  setBoolean('jotFormShown', true);
  yield* fork(navigate, '/form');
}

export function* navigateToForgotPassword() {
  yield* fork(navigate, '/forgot-password');
}

export function* navigateToForgotPasswordCheck() {
  yield* fork(navigate, '/forgot-password-check');
}

export function* navigateToNewPassword() {
  yield* fork(navigate, '/new-password');
}

export function* navigateToCheckEmail(replace?: boolean) {
  yield* fork(navigate, '/check-email', replace);
}

export function* navigate(to: string, replace?: boolean) {
  const { options } = authState;

  const nav = options.navigate;

  if (typeof nav !== 'function') {
    console.error('navigate is not defined');
    return;
  }

  const { pathname } = document.location;

  // get pathName from url
  const toPathName = to.split('?')[0];

  if (pathname === toPathName) {
    return;
  }

  if (!to.includes('?')) {
    to += document.location.search;
  }

  logger.Navigation({ to, replace });

  nav(to, { replace });
}

export function* root() {
  yield takeEvery('TO_LOGIN', navigateToLogin);
  yield takeEvery('TO_FORGOT_PASSWORD', navigateToForgotPassword);
}
