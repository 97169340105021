import { User } from 'firebase/auth';
import { AuthOptions, AuthType, LoginProviderAll, RouteType } from '../types';
import { getBoolean } from 'shared-base';

export class AuthState {
  public options: AuthOptions;
  public routeType: RouteType = 'unknown';
  public lastProvider?: LoginProviderAll;
  public user: Json = {};
  public authType?: AuthType;
  public isNewUser?: boolean;
  public isManual?: boolean;

  constructor(options: AuthOptions) {
    this.options = options;
    this.isNewUser = !getBoolean('USER_LOGGED_IN_ONCE');
    this.authType = this.isNewUser ? 'SIGNUP' : 'LOGIN';
  }

  setUser(user: User | null) {
    if (!user) {
      return;
    }

    let { email, displayName } = user;

    if (!displayName) {
      displayName = (email ?? '').split('@')[0];
    }

    this.user.name = displayName;
    this.user.email = email;
  }

  setApiKey(apiKey: string) {
    this.user.apiKey = apiKey;
  }

  getLogsProps() {
    return {
      authType: this.authType!,
      authMethod: this.lastProvider,
    };
  }
}
