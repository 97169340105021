import type { IMenuItem } from '@ai21/studio-base-ui';
import type { IMenuConfig, IMenuGroup } from '../types';

export class MenuBuilder implements IMenuBuilder {
  private email: string = '';
  private output: IMenuConfig = {
    items: [],
    groups: [],
  };
  private featureFlags: Record<string, boolean> = {};

  constructor() {}

  withItems(items: IMenuItem[]) {
    this.output.items.push(...items);
    return this;
  }

  withEmail(email: string) {
    this.email = email;
    return this;
  }

  withFeatureFlags(featureFlags: Record<string, boolean>) {
    this.featureFlags = featureFlags;
    return this;
  }

  withGroups(groups: IMenuGroup[]) {
    this.output.groups.push(...groups);
    return this;
  }

  build() {
    const isEmployee = this.email.endsWith('@ai21.com');

    this.output.items = this.output.items.filter((item) => {
      if (item.isLabs) {
        return isEmployee;
      }

      if (item.featureFlag) {
        return this.featureFlags[item.featureFlag];
      }

      return true;
    });

    return this.output;
  }
}

interface IMenuBuilder {
  build(): IMenuConfig;
}
