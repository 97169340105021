import { LoginContext } from '@ai21/studio-auth';
import { actions, selectors } from '@ai21/studio-store';
import { IMenuItem, MenuBuilder, SideBar } from '@ai21/studio-ui';
import { useExperiment } from '@ai21/studio-experiment';
import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigate } from '../sagas/saga.navigation';
import { initSideBar } from './Web.apps';
import { hideMenuPaths } from './SideBar.data';
import { WebRoutes } from './Web.routes';
import { useMobile } from '../hooks/useMobile';
import { useFetchVariants } from '../../../packages/studio-experiment/dist/hooks/useExperiment';

export function SideBarContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userStatus, userEmail, user } = useContext(LoginContext).state;
  const isSlim = useSelector(selectors.raw.$rawAppState).slimSideBar;
  const isMobile = useMobile();
  const {
    isLoading: isExperimentsLoading,
    values: [ragChatPageEnabled],
  } = useExperiment('rag-chat-page-enabled');

  const config = useMemo(() => {
    const menuBuilder = new MenuBuilder();
    menuBuilder.withEmail(userEmail);
    menuBuilder.withFeatureFlags({ 'rag-chat-page-enabled': ragChatPageEnabled === 'on' });
    initSideBar(menuBuilder);
    return menuBuilder.build();
  }, [userStatus, userEmail, isExperimentsLoading, ragChatPageEnabled]);

  const callbacks = useMemo(
    () => ({
      onBack: () => {
        navigate(WebRoutes.home);
      },
      onClick: (item: IMenuItem) => {
        const { path, href, actionType } = item;

        if (isMobile) {
          dispatch(actions.appState.patch({ slimSideBar: true }));
        }

        if (actionType) {
          dispatch({ type: actionType });
        } else {
          navigate(path || href!);
        }
      },
      onInfoClick: () => {},
    }),
    []
  );

  const noBars = hideMenuPaths.find((path) => path.startsWith(location.pathname.split('/')[1]));

  useEffect(() => {
    if (noBars) {
      document.body.classList.add('no-bars');
    } else {
      document.body.classList.remove('no-bars');
    }
  }, [noBars]);

  if (noBars) {
    return null;
  }

  return (
    <SideBar
      items={config.items}
      groups={config.groups}
      onClick={callbacks.onClick}
      onBack={callbacks.onBack}
      isSlim={isSlim}
    />
  );
}
