import { setTokens, setUserId, setOrganizationId } from '@ai21/studio-api';
import { call, fork, put, takeLatest, delay } from 'saga-ts';
import { getBoolean, invokeEvent } from 'shared-base';
import { authState, firebase } from '../utils/globals';
import { Logger, logAnalytics, logger } from '../utils/log';
import { setBoolean } from '../utils/storage';
import { navigateToJotform, navigateToOriginalLocation } from './helpers/navigation';
import { createUser, getUser } from './helpers/user';

export type ActionLastStep = {
  type: 'AUTH_LAST_STEP';
  user: any;
};

export function* onAuthenticationCompleted(action: ActionLastStep) {
  const { user } = action;

  const isNewUser = firebase.isNewUser(user);

  setTokens({ firebaseIdToken: user.accessToken });

  authState.setUser(user);

  let response: any;

  if (isNewUser) {
    user.isNewUser = true;
    const token = yield* call(firebase.getIdToken, user);
    setTokens({ firebaseIdToken: token });
    response = yield* call(createUser, user);
  } else {
    response = yield* call(getUser, user);
  }

  if (!response.isSuccess) {
    return;
  }
  const { apiKey, organizationId } = response.data;
  setTokens({ studioApiKey: apiKey });
  setUserId(user.uid);
  setOrganizationId(organizationId);
  authState.setApiKey(apiKey);

  invokeEvent('FIREBASE_USER_READY', user);

  logger.AuthSuccess();
  setBoolean('USER_LOGGED_IN_ONCE', true);

  yield put({ type: 'AUTHENTICATION_COMPLETED' });

  if (authState.isManual) {
    logger.ManualAuthSucceeded();
  }

  // wrap up => navigate to original location or jotform

  // we make sure jotForm is shown only once
  if (isNewUser && !getBoolean('jotFormShown')) {
    yield* fork(navigateToJotform);
    logger.AuthCompleted();
    return;
  }

  yield* call(navigateToOriginalLocation);

  logger.AuthCompleted();
}

export function* root() {
  yield takeLatest('saga/complete', onAuthenticationCompleted);
}
