import { fork, put, take, takeEvery } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import { authState } from '../utils/globals';
import { logger } from '../utils/log';
import { authChangeChannel } from './channels/channel.authChange';
import { navigateToLogin } from './helpers/navigation';
import { checkLimboState } from './saga.email';
import { User } from 'firebase/auth';

// step #3
export function* authChange(event: any) {
  const { user } = event;

  authState.setUser(user);
  logger.CheckCurrentUser();

  if (!user) {
    yield* fork(navigateToLogin, true);
    return;
  }

  const emailShouldNotBeVerified = isGithubUser(user) || isWorkOSUser(user);
  const isEmailVerified = user.emailVerified;

  yield* fork(checkLimboState, user);

  invokeEvent('user', { user });

  if (user && user.accessToken && (isEmailVerified || emailShouldNotBeVerified)) {
    yield put({ type: 'saga/complete', user });
  }
}

const isGithubUser = (user: User) => {
  try {
    return user.providerData[0].providerId === 'github.com';
  } catch (err: any) {
    return false;
  }
};

export const isWorkOSUser = (user: User) => {
  try {
    return user.providerData[0].providerId === 'oidc.workos';
  } catch (err: any) {
    return false;
  }
};

export function* root() {
  yield take('saga/auth');

  const channel = authChangeChannel();
  yield takeEvery(channel, authChange);
}
