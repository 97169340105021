import { useMount } from 'react-use';
import { addScriptTagToHead } from './script';

const hotJarKey = window.__env__.VITE_HOTJAR_KEY;

export function useHotJar() {
  useMount(() => {
    if (!hotJarKey) {
      return;
    }

    addScriptTagToHead('ai21-hotjar', scriptContent(hotJarKey));
  });
}

const scriptContent = (key: string) => `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:${key},hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
